@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

* {
    font-family: 'Cabin', sans-serif
}
body {
    margin: 0px;
    padding: 0px;
}
html {
    background: #02020d;
}